import {Image} from '@shopify/hydrogen';
import {Collection, MediaImage} from '@shopify/hydrogen/storefront-api-types';
import {SRC_SET_SIZES} from '~/lib/const';
import {getSrcSetSizes} from '~/lib/utils';
import {Button, Link} from '~/components';

export function CollectionHero({
  collection,
  type = 'textWithImage',
}: {
  collection: Collection;
  type?: 'banner' | 'card' | 'textWithImage' | 'text';
}) {
  if (type === 'banner') {
    return banner({collection});
  } else if (type === 'card') {
    return card({collection});
  } else if (type === 'text') {
    return text({collection});
  } else {
    return textWithImage({collection});
  }
}

function text({collection}: {collection: Collection}) {
  return (
    <div className="container mx-auto text-center">
      <h1>{collection.title}</h1>

      {collection?.descriptionHtml ? (
        <div
          dangerouslySetInnerHTML={{__html: collection.descriptionHtml}}
        ></div>
      ) : (
        <p className="max-w-3xl mx-auto">{collection.description}</p>
      )}
    </div>
  );
}

function textWithImage({collection}: {collection: Collection}) {
  return (
    <div className="lg:relative w-full grid grid-cols-1 md:grid-cols-2 bg-gray-100 overflow-hidden">
      <div className="p-16 flex flex-col items-start justify-center h-full">
        {collection?.descriptionHtml && (
          <div
            dangerouslySetInnerHTML={{__html: collection.descriptionHtml}}
          ></div>
        )}
        <div className="mt-8 w-full flex justify-start">
          <Button
            as={Link}
            variant="primary"
            to={`/collections/${collection.handle}`}
            className="uppercase"
          >
            SCOPRI DI PIÙ
          </Button>
        </div>
      </div>
      <div className="relative w-full aspect-square h-[600px] lg:h-[calc(100vh_-_160px)]">
        {collection.image && (
          <Image
            data={collection.image}
            alt={collection.title}
            widths={SRC_SET_SIZES}
            loaderOptions={{crop: 'center', width: 1, height: 1}}
            sizes={getSrcSetSizes([100, 100, 50, 50, 50, 50])}
            className="absolute inset-0 w-full h-full object-cover"
            loading="lazy"
          ></Image>
        )}
      </div>
    </div>
  );
}

function card({collection}: {collection: Collection}) {
  return (
    <div className="flex flex-col overflow-hidden relative min-h-[600px]">
      <Image
        data={collection.image!}
        alt={collection.title}
        widths={SRC_SET_SIZES}
        sizes={getSrcSetSizes([90, 90, 90, 33, 33, 33])}
        loaderOptions={{crop: 'center', width: 2, height: 3}}
        className="absolute inset-0 h-full w-full object-cover z-0 snap-center object-center center transition-transform duration-1000 hover:scale-105"
        loading="lazy"
      ></Image>
      <div className="absolute w-full bottom-0 flex flex-col items-center justify-end ">
        <div className="pb-12 flex flex-col items-center">
          {collection?.descriptionHtml && (
            <div
              className="text-white pb-12"
              dangerouslySetInnerHTML={{__html: collection.descriptionHtml}}
            ></div>
          )}
          <Button
            as={Link}
            variant="secondary"
            to={`/collections/${collection.handle}`}
            className="uppercase"
          >
            SCOPRI
          </Button>
        </div>
      </div>
    </div>
  );
}

function banner({collection}: {collection: Collection}) {
  const mobileMediaImage: MediaImage | any = collection.metafields?.find(
    (meta) => meta?.key === 'mobile_media',
  )?.reference;

  return (
    <div className="relative w-full h-[calc(100vh_-_48px)] md:h-[calc(100vh_-_160px)]">
      <div className="z-10 h-full w-full">
        {/* FIXME se facciamo questa cosa di mettere due immagini, senza picture 
        dobbiamo inviarle lato server e per evitare di caricarle entrambe dobbiamo mettere lazy
        che pero' rallenta il caricamento del LCP  da capire se possiamo risolverlo con il preload
        solita cosa avviene anche con il menu che sono stampati entrambi*/}
        <Image
          fetchpriority="high"
          loading="lazy"
          data={collection.image!}
          alt={collection.title}
          widths={SRC_SET_SIZES}
          sizes={getSrcSetSizes([90, 90, 90, 90, 90, 90])}
          loaderOptions={{crop: 'center', width: 16, height: 9}}
          className="inset-0 h-full w-full object-cover z-0 snap-center object-center  center hidden md:block"
        ></Image>

        {mobileMediaImage && (
          <Image
            fetchpriority="high"
            loading="lazy"
            data={mobileMediaImage?.image}
            alt={collection.title}
            widths={SRC_SET_SIZES}
            sizes={getSrcSetSizes([90, 90, 90, 90, 90, 90])}
            loaderOptions={{crop: 'center', width: 2, height: 3}}
            className="inset-0 h-full w-full object-cover z-0 snap-center object-center center block md:hidden"
          ></Image>
          // block md:hidden
        )}
      </div>
      <div className="absolute bottom-12 flex h-full w-full items-end justify-center p-8 z-20">
        <div className="z-20 flex flex-col justify-center">
          <div className="mb-12 text-center ">
            <p className="text-white">{collection.description}</p>
          </div>
          <Button
            as={Link}
            variant="secondary"
            to={`/collections/${collection.handle}`}
            className="uppercase"
          >
            SCOPRI DI PIÙ
          </Button>
        </div>
      </div>
    </div>
  );
}
